/****
let algolia = window.algoliaShopify;

document.addEventListener("algolia.hooks.initialize", function() {
  algoliaShopify.hooks.registerHook('beforeInstantSearchFiltersString', function(defaultFilter)  {
    const partner = window.BARREL.partner;

    if (defaultFilter) {
      return  defaultFilter + ' AND meta.Algolia.Partners:' + partner;
    }
    else {
      return 'meta.Algolia.Partners:' + partner;
    }
  });
});
****/

document.addEventListener("algolia.hooks.initialize", function () {
  algoliaShopify.hooks.registerHook(
    "beforeAutocompleteFiltersString",
    function (defaultFilter) {
      const partner = window.BARREL.partner;
      console.log("beforeAutocompleteFiltersString", { partner });

      if (defaultFilter) {
        return defaultFilter + " AND meta.Algolia.Partners:" + partner;
      } else {
        return "meta.Algolia.Partners:" + partner;
      }
    }
  );

  algoliaShopify.hooks.registerHook(
    "beforeInstantSearchFiltersString",
    function (defaultFilter) {
      const partner = window.BARREL.partner;
      console.log("beforeInstantSearchFiltersString", { partner });

      if (defaultFilter) {
        return defaultFilter + " AND meta.Algolia.Partners:" + partner;
      } else {
        return "meta.Algolia.Partners:" + partner;
      }
    }
  );

  algoliaShopify.hooks.registerHook(
    "beforeInstantSearchFiltersString",
    function (defaultFilter) {
      if (defaultFilter && defaultFilter.includes("collection_ids")) {
        const collectionIDFilterRegex = /collection_ids:"\w+"/g;
        const matches = window.location.pathname.match(
          /\/collections\/([^/]+)/i
        );
        const collectionHandle =
          matches && matches.length === 2 ? matches[1] : null;
        if (collectionHandle) {
          return defaultFilter.replace(
            collectionIDFilterRegex,
            "collections:" + collectionHandle
          );
        }
      }
      return defaultFilter;
    }
  );

  algoliaShopify.hooks.registerHook(
    "beforeInstantSearchMainTemplate",
    function (_defaultTemplate, data, html) {
      return html`
        <div class="ais-page">
          <h1 class="ais-h2">${algoliaShopify.translations.searchTitle}</h1>
          <div class="ais-input">
            <div class="ais-search-box-container"></div>
            <div class="ais-input-button">
              <div class="ais-clear-input-icon"></div>
            </div>
          </div>
          <div class="ais-facets-button">Show filters</div>
          <div class="ais-facets">
            <div class="ais-clear-refinements-container"></div>
            <div class="ais-current-refined-values-container"></div>
            ${data.facets.map(
              (facet) =>
                html`
                  <div
                    class="ais-facet-dropdown-wrapper ais-facet-${facet.type} ais-facet-${facet.escapedName}"
                  >
                    <label
                      for="${facet.escapedName}"
                      class="ais-range-slider--header ais-facet--header ais-header"
                      >${facet.title}</label
                    >
                    <input
                      class="ais-dropdown-checkbox"
                      type="checkbox"
                      id="${facet.escapedName}"
                      name="dropdown"
                    />
                    <div
                      class="ais-facet-${facet.escapedName}-container ais-facet-dropdown-container"
                    ></div>
                  </div>
                `
            )}
          </div>
          <div class="ais-block">
            <div class="ais-search-header">
              <div class="ais-stats-container"></div>
              <div class="ais-change-display">
                <span
                  class="ais-change-display-block ais-change-display-selected"
                  ><i class="fa fa-th-large"></i
                ></span>
                <span class="ais-change-display-list"
                  ><i class="fa fa-th-list"></i
                ></span>
              </div>
              <div class="ais-sort">
                ${data.multipleSortOrders
                  ? html`
                      ${algoliaShopify.translations.sortBy}
                      <span class="ais-sort-orders-container"></span>
                    `
                  : html`
                      ${algoliaShopify.translations.sortBy}
                      ${algoliaShopify.translations.relevance}
                    `}
              </div>
            </div>
            <div class="ais-hits-container ais-results-as-block"></div>
          </div>
          <div class="ais-pagination-container"></div>
        </div>
      `;
    }
  );

  algoliaShopify.hooks.registerHook(
    "beforeInstantSearchProductTemplate",
    function (_defaultTemplate, hit, html, components) {
      try {
        // return html`
        // <p>
        //   <div>
        //     ${hit.product_image}
        //   </div>
        //   <div>
        //     ${hit.vendor}
        //   </div>
        //   <div>
        //     ${hit.title}
        //   </div>
        //   <div>
        //     ${hit.sku}
        //   </div>
        //   <div>
        //     ${hit.price}
        //   </div>
        // </p>
        // `
        const MOQ_tag = hit.tags.find((s) => s.includes("MOQ_"));
        let MOQ = 0;
        if (MOQ_tag) {
          MOQ = Number(MOQ_tag.split("_")[1] || 0);
        }
        return html`
          <a
            href="${algoliaShopify.helpers.instantsearchLink(hit)}"
            class="ais-hit ais-product override"
            data-algolia-index="${hit.index}"
            data-algolia-position="${hit.productPosition}"
            data-algolia-queryid="${hit.queryID}"
            data-algolia-objectid="${hit.objectID}"
            data-handle="${hit.handle}"
            data-variant-id="${hit.objectID}"
            data-distinct="${hit._distinct}"
            data-product-id="${hit.id}"
            data-qty="${MOQ || 1}"
            data-available="${hit.can_order}"
          >
            <div class="ais-hit--wishlist" data-heart-filled="false">
              <span class="wishlist__heart-full" data-fill="false">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.647 2.2788C11.342 2.2788 10.0895 2.8863 9.27197 3.8463C8.45447 2.8863 7.20197 2.2788 5.89697 2.2788C3.58697 2.2788 1.77197 4.0938 1.77197 6.4038C1.77197 9.2388 4.32197 11.5488 8.18447 15.0588L9.27197 16.0413L10.3595 15.0513C14.222 11.5488 16.772 9.2388 16.772 6.4038C16.772 4.0938 14.957 2.2788 12.647 2.2788Z"
                    fill="#000"
                  />
                </svg>
              </span>
              <span class="wishlist__heart-empty ">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 19 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.77197 3.81749C10.5895 2.85749 11.842 2.24999 13.147 2.24999C15.457 2.24999 17.272 4.06499 17.272 6.37499C17.272 9.20767 14.7262 11.5162 10.869 15.0139L10.8595 15.0225L9.77197 16.0125L8.68447 15.03L8.65486 15.0031C4.80895 11.5082 2.27197 9.20274 2.27197 6.37499C2.27197 4.06499 4.08697 2.24999 6.39697 2.24999C7.70197 2.24999 8.95447 2.85749 9.77197 3.81749ZM9.77198 13.9875L9.84698 13.9125C13.417 10.68 15.772 8.54253 15.772 6.37503C15.772 4.87503 14.647 3.75003 13.147 3.75003C11.992 3.75003 10.867 4.49253 10.477 5.52003H9.07448C8.67698 4.49253 7.55198 3.75003 6.39698 3.75003C4.89698 3.75003 3.77198 4.87503 3.77198 6.37503C3.77198 8.54253 6.12698 10.68 9.69698 13.9125L9.77198 13.9875Z"
                    fill="#BDBDBD"
                  />
                </svg>
              </span>
            </div>
            <img
              class="ais-hit--picture"
              src="${algoliaShopify.helpers.largeImage(hit)}"
              alt="${hit.title} - ${hit.variant_title}"
            />

            <div class="ais-hit--details">
              <div
                class="product-card__badges empty:hidden mt-2 mb-1 flex flex-wrap h-[25px] lg:h-[27px]"
              >
                <div class="flex items-center">
                  <span
                    class="badge mr-1 mb-1 p-1 p2 font-bold product-card__badge bg-yellow text-black black-decker"
                    >${hit.vendor}</span
                  >
                </div>
              </div>
              <p class="ais-hit--sku">${hit.sku}</p>
              <p class="ais-hit--title override">
                <a
                  data-algolia-index="${hit.index}"
                  data-algolia-position="${hit.productPosition}"
                  data-algolia-queryid="${hit.queryID}"
                  data-algolia-objectid="${hit.objectID}"
                  href="${algoliaShopify.helpers.instantsearchLink(hit)}"
                  onclick="void(0)"
                  title="${algoliaShopify.helpers.fullTitle(
                    hit.title,
                    hit._distinct,
                    hit.variant_title
                  )}"
                >
                  ${components.Highlight({ attribute: "title", hit })}
                  ${algoliaShopify.helpers.variantTitleAddition(
                    hit,
                    hit._distinct
                  )}
                </a>
              </p>
              <p
                class="ais-hit--subtitle"
                title="${hit.sku}${algoliaShopify.translation_helpers.by(
                  hit.vendor
                )}"
              >
                ${components.Highlight({ attribute: "vendor", hit })}
              </p>
              <p class="ais-hit--price">
                ${algoliaShopify.helpers.displayPrice(hit, hit._distinct)}
                ${!hit._distinct &&
                html`
                  <span class="ais-hit--price-striked"
                    ><span
                      >${algoliaShopify.helpers.displayStrikedPrice(
                        hit.price,
                        hit.compare_at_price
                      )}</span
                    ></span
                  >
                `}
              </p>
              <p class="ais-hit--moq">
                ${MOQ > 1 ? html`Must buy in multiples of ${MOQ}` : html``}
              </p>
              ${!hit._distinct &&
              html`
                <form
                  id="algolia-add-to-cart-${hit.objectID}"
                  style="display: none;"
                  action="/cart/add"
                  method="post"
                  enctype="multipart/form-data"
                >
                  <input type="hidden" name="id" value="${hit.objectID}" />

                  ${MOQ > 1
                    ? html`<input
                        type="hidden"
                        name="quantity"
                        value="${MOQ}"
                      />`
                    : html`<input type="hidden" name="quantity" value="1" />`}
                </form>
                <p class="ais-hit--cart">
                  ${hit.can_order
                    ? html`
                        <button
                          class="ais-hit--cart-button"
                          data-form-id="algolia-add-to-cart-${hit.objectID}"
                        >
                          ${algoliaShopify.translations.addToCart}
                        </button>
                      `
                    : html`
                        <button
                          class="ais-hit--cart-button ais-hit--cart-button__disabled"
                        >
                          ${algoliaShopify.translations.outOfStock}
                        </button>
                      `}
                </p>
              `}
            </div>
          </a>
        `;
      } catch (e) {
        console.log("Alogila error", e);
      }
    }
  );
});

// function toggleWishlist(productCard) {
//   let wishlist = productCard.querySelector(".ais-hit--wishlist");
//   if (wishlist.dataset.heartFilled !== "true") {
//     wishlist.querySelector(".wishlist__heart-full").style.display = "block";
//     wishlist.querySelector(".wishlist__heart-empty").style.display = "none";
//     wishlist.setAttribute("data-heart-filled", true);
//   } else {
//     wishlist.querySelector(".wishlist__heart-full").style.display = "none";
//     wishlist.querySelector(".wishlist__heart-empty").style.display = "block";
//     wishlist.setAttribute("data-heart-filled", false);
//   }
// }

// async function makeRequest(path, method, payload = null) {
//   try {
//     const endpoint = window.BARREL.sbdApiUrl;
//     const token = window.BARREL.sbdApiAccessToken;
//     const url = `${endpoint}/${path}`;

//     const reqConfig = {
//       method,
//       headers: {
//         token,
//         mode: "no-cors",
//       },
//     };
//     if (payload) {
//       reqConfig.headers["Content-Type"] = "application/json";
//       reqConfig.body = JSON.stringify(payload);
//     }
//     const response = await fetch(url, reqConfig);
//     if (!response.ok) {
//       throw new Error(`${response.status} - ${response.statusText}`);
//     }
//     const data = await response.json();

//     return data;
//   } catch (error) {
//     console.error(`Error makeRequest: ${method} - ${error}`);
//   }
// }

// async function handleChangeWishlist(event) {
//   event.stopPropagation();
//   const customerId = window.BARREL.customerId;
//   // console.log('wish click', event.target.closest('.ais-product'));
//   let productCard = event.target.closest(".ais-product");

//   if (productCard.dataset.wishlistId) {
//     const result = await makeRequest("removeWishListItem", "POST", {
//       customerId,
//       wishListItemId: productCard.dataset.wishlistId,
//     });
//     if (result) {
//       toggleWishlist(productCard);
//       productCard.removeAttribute("data-wishlist-id");
//     }
//   } else {
//     const result = await makeRequest("addWishListItem", "POST", {
//       customerId,
//       productId: productCard.dataset.productId,
//       handle: productCard.dataset.handle,
//       variantId: productCard.dataset.variantId,
//       qty: productCard.dataset.qty,
//       // title: productCard.dataset.title,
//     });
//     if (result) {
//       toggleWishlist(productCard);
//       productCard.setAttribute("data-wishlist-id", result.data.wishListItemId);
//     }
//   }
// }

// async function loadWishlistItems() {
//   try {
//     const customerId = window.BARREL.customerId;
//     const pageSize = 1000;

//     const response = await makeRequest(
//       `wishlist/list/${customerId}?pageSize=${pageSize}`,
//       "GET"
//     );
//     if (response) {
//       const wishlistItems = response.data;
//       for (let i = 0; i < wishlistItems.length; i++) {
//         const { wishListItemID, productID } = wishlistItems[i];
//         const productCard = document.querySelector(
//           `[data-product-id="${productID}"]`
//         );
//         if (productCard) {
//           productCard.setAttribute("data-wishlist-id", wishListItemID);
//           // toggleWishlist(productCard);
//           let wishlist = productCard.querySelector(".ais-hit--wishlist");

//           wishlist.querySelector(".wishlist__heart-full").style.display =
//             "block";
//           wishlist.querySelector(".wishlist__heart-empty").style.display =
//             "none";
//           wishlist.setAttribute("data-heart-filled", true);
//         }
//       }
//     }
//   } catch (error) {
//     console.error("Error loadWishlistItems: ", error.message);
//   }
// }

// document.addEventListener("algolia.instantSearch.onRender", async () => {
//   let wishlistItems = document.querySelectorAll(".ais-hit--wishlist");
//   if (wishlistItems.length) {
//     await loadWishlistItems();
//     wishlistItems.forEach((ele) => {
//       ele.addEventListener("click", handleChangeWishlist);
//     });
//   }
// });
